import React, { useState, useEffect } from "react";
import BxMenu from "../assets/images/bx-menu.svg";
import { Modal, Button } from "antd"; // Using Ant Design for modal
import { useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons"; // Using Ant Design icon
import { post } from "../lib/HttpServices";
import { toast } from "react-toastify";

const Header = ({ toggleSidebar, reg_user_count }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [name, setNmae] = useState("");
  const navigate = useNavigate();
  const [logoutTrigger, setlogoutTrigger] = useState(false);
  const pathNameLink = window.location.pathname;
  // const reg_user_count = localStorage.getItem('reg_users_count'|| 0);
  const showLogoutModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    const temp_name = localStorage.getItem("name");
    setNmae(temp_name);
  }, []);

  useEffect(() => {
    // setloading(true);
    if (logoutTrigger) {
      post("logout")
        .then((response) => {
          toast.success("Logout successfull ", {
            autoClose: 3000,
          });
          localStorage.removeItem("ChatData");
          localStorage.removeItem("refresh_token");
          navigate("/");
        })
        .catch((error) => {
          if (error.response.status === 500) {
            toast.error(
              "Internal Server Error (500). Please try again later.",
              {
                autoClose: 2000,
              }
            );
          }
          localStorage.removeItem("ChatData");
          localStorage.removeItem("refresh_token");
          navigate("/");
        });
    }
  }, [logoutTrigger]);

  const handleLogout = () => {
    // Remove token and redirect to login page
    setlogoutTrigger(true);
    // localStorage.removeItem('ChatData');
    // localStorage.removeItem('refresh_token');
    // navigate('/');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="home-content">
      <div className="d-flex align-items-center w-100">
        <span className="bx-menu" onClick={toggleSidebar}>
          <img src={BxMenu} alt="" style={{ width: "25px" }} />
          <h4>
            {pathNameLink === "/dashboard"
              ? "Patient Data"
              : pathNameLink === "/content-management"
              ? "Content Management"
              : pathNameLink === "/user-queries"
              ? "User Queries"
              : pathNameLink === "/registered-user"
              ? "Registered User"
              : pathNameLink === "/product-and-services"
              ? "Products & Services"
              : pathNameLink === "/appointments"
              ? "User Uploads"
              : ""}
          </h4>
          {pathNameLink === "/registered-user" && (
            <div
              style={{
                background: "rgb(164, 171, 200)",
                padding: "3px 14px",
                color: "#fff",
                borderRadius: "16px",
                fontSize: "14px",
                marginLeft: "15px",
              }}
            >
              {reg_user_count || 0}
            </div>
          )}
        </span>
        <div className="custom-menubar-item d-flex justify-content-end w-100 pe-5 align-items-center">
          <div className="d-flex justify-content-center align-items-center">
            <div className="profile-img-content">
              <h6 className="mb-0">Welcome {name}</h6>
            </div>
            <div
              className="logout-icon"
              onClick={showLogoutModal}
              style={{ cursor: "pointer", marginLeft: "15px" }}
            >
              <LogoutOutlined style={{ fontSize: "24px", color: "#4d6a9e" }} />
            </div>
          </div>
        </div>
      </div>

      {/* Logout Confirmation Modal */}
      <Modal
        title="Confirm Logout"
        visible={isModalVisible}
        onOk={handleLogout}
        onCancel={handleCancel}
        okText="Logout"
        cancelText="Cancel"
      >
        <p>Are you sure you want to log out?</p>
      </Modal>
    </div>
  );
};

export default Header;
