import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../components/Sidebar";
import { DatePicker, message } from "antd";
import Header from "../components/Header";
import { Input } from "antd";
import LeadsImg from "../assets/images/leads.svg";
import CarbonLocation from "../assets/images/carbon_location.svg";
import sortImg from "../assets/images/sort.svg";
import dateImg from "../assets/images/date.svg";
import SearchImg from "../assets/images/search.svg";
import DocumentImg from "../assets/images/document.svg";
import DocumentMenuImg from "../assets/images/drpdown-menu.svg";
// import DocumentImg from "../assets/images/document.svg";
import DownloadMenuImg from "../assets/images/download.png";
import ViewMenuImg from "../assets/images/view.png";
import { Modal } from "antd";
import { Button, Dropdown, Space, Menu, Select } from "antd";
import {
  DownOutlined,
  SmileOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import BarDiagram from "../components/BarDiagram";
import DynamicTable from "../components/DynamicTable";
import DataTable from "react-data-table-component";
import LocationCheckboxDropdown from "../components/filterByLocation";
import FilterByDate from "../components/filterByDate";
import { Link, useNavigate } from "react-router-dom";
import { get,post } from "../lib/HttpServices";
import axios from "axios";
import { toast } from "react-toastify";
import { parse, format } from "date-fns";
// import { Line } from '@ant-design/charts';
import {
  Line,
  LineChart,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  XAxis,
  ResponsiveContainer,
} from "recharts";
import { EyeOutlined, DownloadOutlined, MoreOutlined } from "@ant-design/icons";

const lineData = [
  { date: "Sep 10 2024", leads: 12 },
  { date: "Sep 11 2024", leads: 15 },
  { date: "Sep 12 2024", leads: 9 },
  { date: "Sep 13 2024", leads: 7 },
  { date: "Sep 14 2024", leads: 18 },
  { date: "Sep 15 2024", leads: 22 },
  { date: "Sep 16 2024", leads: 19 },
];
const items = [
  {
    key: "4",
    label: "Last 7 Days",
    value: "Last 7 Days",
  },
  {
    key: "1",
    label: "Last 14 Days",
    value: "Last 14 Days",
  },
  {
    key: "2",
    label: "Last 30 Days",
    value: "Last 30 Days",
  },
  {
    key: "3",
    label: "Last 60 Days",
    value: "Last 60 Days",
  },
];
// 


// 
const DashboardCopy = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selected, setSelected] = useState("Last 7 Days"); // Initial selected value
  const [selectedLocmenu, setselectedLocmenu] = useState("Last 7 Days"); // Initial selected value
  const [totalLeadPercentage, setotalLeadPercentage] = useState("51");
  const [totalLeads, setotalLead] = useState("--");
  const [locationfullList, setlocationfullList] = useState([]);
  const [leadgraphData, setleadgraphData] = useState([]);
  const [totalLocations, setotalocations] = useState("--");
  const [LocationBarData, setlocationbardata] = useState([]);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [leadCustomerData, setleadCustomerData] = useState([]);
  const [timer, setTimer] = useState(15);
  const [filterCustomerData, setfilterCustomerData] = useState([]);
  const [fiterText, setfiterText] = useState("");
  const [selectedLocations, setselectedLocations] = useState([]);
  const [fileType, setFileType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [listofdoc,setListsofDoc] = useState([]);
  const [patientName,setpatientName] = useState("");
  const [patientContactNumber,setpatientContactNumber] = useState("");
  const [selectedlisteofdoc,setselectedlisteofdoc] = useState([])
  const [errors, setErrors] = useState({});
  const [requestedpatientContactNumber,setRequestedpatientContactNumber] = useState("")
  const [requestedpatientName,setRequestedpatientName] = useState("");
  const [selectedRequestedlisteofdoc,setselectedRequestedlisteofdoc] = useState([])
  const [selectedDefaultRequestedlisteofdoc,setselectedDefaultRequestedlisteofdoc] = useState([])
  const [isEdit, setIsEdit] = useState(false);

  useEffect(()=>{
    
    get(`get_documents_list`).
    then((res)=>{
      setListsofDoc(res.data.documents)

    })
  },[])

  const options = listofdoc.map((doc)=>({
    value :doc.id,
    label : doc.title
  
  }));

  const handlePatientName = (e)=>{
    setpatientName(e.target.value)
  }

  const handleContactNumber = (e)=>{
    setpatientContactNumber(e.target.value)
  }

  // const handleChange = (value) => {
  //   console.log(`selected ${value}`);
  //   setselectedlisteofdoc(value)
  // };

  const handleChange = (value) => {
    console.log("Updated selected documents:", value);
    setselectedlisteofdoc(value); // Directly update the state with the current value
  };
  

  const handleRequestChange = (value) =>{
    setselectedRequestedlisteofdoc(value)
  }

  const showModal = () => {
    setpatientName("")
    setpatientContactNumber("")
    setselectedlisteofdoc([])
    setIsEdit(false)
    setErrors({})
    setIsModalOpen(true);
    
  };
  // console.log("filterCustomerData::",filterCustomerData)
  // console.log("selectedlisteofdoc::",selectedlisteofdoc)
  // console.log("listofdoc::",listofdoc)

  const validateInputs = () => {
    const newErrors = {};

    // Validate name
    if (!patientName.trim()) {
      newErrors.patientName = "Patient Name is required.";
    }

    // Validate phone number
    const phoneRegex = /^[+]?[0-9]{10,15}$/;
    if (!patientContactNumber.trim()) {
      newErrors.patientContactNumber = "Phone number is required.";
    } else if (!phoneRegex.test(patientContactNumber)) {
      newErrors.patientContactNumber = "Invalid phone number format.";
    }

    // Validate document selection
    if (selectedlisteofdoc.length === 0) {
      newErrors.selectedlisteofdoc = "At least one document must be selected.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleOk = () => {
    if (validateInputs()) {
    let payload = {
      user_name: patientName,
      phone_number:patientContactNumber,
      documents:selectedlisteofdoc.map((id)=>({
        document_name: listofdoc.find((doc)=>doc.id === id)?.title || "Unknown",
        status:"requested",
    }))
    }
    console.log("add_patient::",payload)
    post(`add_patient`,payload).
    then((res)=>{
      console.log(res);
       toast.success(res.data?.message, {
                  autoClose: 3000,
                });
      setIsModalOpen(false);
    }).catch((error) => console.error("API Error::", error));
  }
  };
  

 
  // console.log("selectedlisteofdoc::",selectedlisteofdoc);
  // console.log("listofdoc::",listofdoc);
  
  const handleRequestOk = ()=>{
    // setIsRequestModalOpen(false)
    console.log("selectedRequestedlisteofdoc::",selectedRequestedlisteofdoc);
    let payload = {
      user_name: requestedpatientName,
      phone_number:requestedpatientContactNumber,
      documents:selectedRequestedlisteofdoc.map((id)=>({
        document_name: listofdoc.find((doc)=>doc.id === id)?.title || "Unknown",
        status:"requested",
    }))
    }
    console.log("edit_patient::",payload)
    // post(`add_patient`,payload).
    // then((res)=>{
    //    toast.success(res.data?.message, {
    //               autoClose: 3000,
    //             });
    //     setIsRequestModalOpen(false)
    // }).catch((error) => console.error("API Error::", error));
  }


  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // const handleRequestDocument = (row)=>{
  //       console.log("row::",row);
  //   setRequestedpatientContactNumber(row.mobile)
  //   setRequestedpatientName(row.name)
  //   const defaultDocuments = row.requested_documents.map(doc=>doc.document_id)
  //   // console.log("defaultDocuments::",defaultDocuments);
    
  //   setselectedDefaultRequestedlisteofdoc(defaultDocuments);
    
  //   setIsRequestModalOpen(true)
  //   console.log("defaultDocuments::",defaultDocuments);

  // }

  const handleRequestDocument = (row) => {
    console.log("row::", row);
    setIsEdit(true); // Set modal to edit mode
    setpatientName(row.name || ""); // Set name
    setpatientContactNumber(row.mobile || ""); // Set mobile
    setselectedlisteofdoc(
      row.requested_documents.map((doc) => options.find((opt) => opt.label === doc.document_name)?.value) || []
    ); // Set list of documents
    setIsModalOpen(true); // Open modal
  };
  

  const handleRequestCancel= ()=>{
    setIsRequestModalOpen(false)
  }


  const dropdownRef = useRef(null);

  const lead_opt_dict = {
    "Last 7 Days": "7",
    "Last 4 Days": "4",
    "Last 2 Days": "2",
    "Last 1 Day": "1",
    "Last 14 Days": "14",
    "Last 30 Days": "30",
    "Last 60 Days": "60",
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // State to manage dropdown visibility
  const [dropdownOpenRow, setDropdownOpenRow] = useState("");

  const leadData = { total_leads: 136, percentage: 51 };
  const totalLocationData = {
    total_locations: 25,
    bardiagram_data: [
      { country: "AUS", percentage: 10 },
      { country: "BEL", percentage: 100 },
      { country: "CAN", percentage: 90 },
      { country: "DNK", percentage: 90 },
      { country: "FIN", percentage: 80 },
      { country: "FRA", percentage: 70 },
      { country: "GRC", percentage: 30 },
      { country: "HUN", percentage: 70 },
      { country: "IND", percentage: 80 },
      { country: "ITA", percentage: 20 },
      { country: "JP", percentage: 60 },
      { country: "KOR", percentage: 50 },
      { country: "USA", percentage: 100 },
    ],
  };
  const locations = [
    "AUS",
    "BEL",
    "IND",
    "FRA",
    "CAN",
    "HUN",
    "FIN",
    "KOR",
    "USA",
  ];
  const myNewTheme = {
    rows: {
      fontSize: "50px",
    },
  };


  const handleDateRangeChange = (startDate, endDate) => {
    if (startDate && endDate) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      };

      // const startDateString = startDate.toString();
      // const endDateString = endDate.toString();
      const startDateString_formatted = `${startDate
        .toLocaleDateString("en-US", options)
        .replace(/,/g, "")} 00:00:00`;
      const endDateString_formatted = `${endDate
        .toLocaleDateString("en-US", options)
        .replace(/,/g, "")} 00:00:00`;
      setDateRange({ startDateString_formatted, endDateString_formatted });
    } else {
      // Handle case when either or both dates are null (reset)
      setDateRange({
        startDateString_formatted: null,
        endDateString_formatted: null,
      });
    }
  };

  const handleViewClick = (lead_id, name, date, location) => {
    // Navigate to the ChatView component
    navigate(`/chatbot/${lead_id}`, {
      state: { lead_id, name, date, location }, // Pass customer_id and name as state
    });
  };

  // const handleViewClick = (lead_id, name, date, location) => {
  //   // Construct the URL for the new tab
  //    // If needed, you can also store the additional state like lead_id, date, and location in sessionStorage or localStorage
  //    sessionStorage.setItem('chatViewData', JSON.stringify({ lead_id, name, date, location }));
  //   const url = `/chatbot/${name}`;
  //   // Open the new tab
  //   window.open(url, '_blank');

  // };
  const items = [
    {
      key: "1",
      label: (
        <div className="d-flex align-items-center justify-content-between">
          <p
            className="d-flex align-items-center mb-0"
            style={{
              background: "#F1F4F8",
              borderRadius: "50px",
              padding: "8px 11px",
            }}
          >
            <img src={DocumentImg} alt="" style={{ width: "14px" }} />
            <span className="" style={{ padding: "0px 15px" }}>
              Link1.pdf{" "}
            </span>
          </p>
          <Link target="_blank">
            {/* <EyeOutlined  /> */}
            <div
              style={{
                background: "#F1F4F8",
                borderRadius: "50px",
                margin: "0 10px",
                width: "35px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={ViewMenuImg}
                alt=""
                className=""
                style={{ width: "18px" }}
              />
            </div>
          </Link>
          <Link download>
            {/* <DownloadOutlined style={{ fontSize: '19px', background: '#F1F4F8', borderRadius: '50px', padding:'8px'}} /> */}
            <div
              style={{
                background: "#F1F4F8",
                borderRadius: "50px",
                margin: "0 10px",
                width: "35px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={DownloadMenuImg}
                alt=""
                className=""
                style={{ width: "18px" }}
              />
            </div>
          </Link>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="d-flex align-items-center justify-content-between">
          <p
            className="d-flex align-items-center mb-0"
            style={{
              background: "#F1F4F8",
              borderRadius: "50px",
              padding: "8px 11px",
            }}
          >
            <img src={DocumentImg} alt="" style={{ width: "14px" }} />
            <span className="" style={{ padding: "0px 15px" }}>
              Link1.pdf{" "}
            </span>
          </p>
          <Link target="_blank">
            {/* <EyeOutlined  /> */}
            <div
              style={{
                background: "#F1F4F8",
                borderRadius: "50px",
                margin: "0 10px",
                width: "35px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={ViewMenuImg}
                alt=""
                className=""
                style={{ width: "18px" }}
              />
            </div>
          </Link>
          <Link download>
            {/* <DownloadOutlined style={{ fontSize: '19px', background: '#F1F4F8', borderRadius: '50px', padding:'8px'}} /> */}
            <div
              style={{
                background: "#F1F4F8",
                borderRadius: "50px",
                margin: "0 10px",
                width: "35px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={DownloadMenuImg}
                alt=""
                className=""
                style={{ width: "18px" }}
              />
            </div>
          </Link>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="d-flex align-items-center justify-content-between">
          <p
            className="d-flex align-items-center mb-0"
            style={{
              background: "#F1F4F8",
              borderRadius: "50px",
              padding: "8px 11px",
            }}
          >
            <img src={DocumentImg} alt="" style={{ width: "14px" }} />
            <span className="" style={{ padding: "0px 15px" }}>
              Link1.pdf{" "}
            </span>
          </p>
          <Link target="_blank">
            {/* <EyeOutlined  /> */}
            <div
              style={{
                background: "#F1F4F8",
                borderRadius: "50px",
                margin: "0 10px",
                width: "35px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={ViewMenuImg}
                alt=""
                className=""
                style={{ width: "18px" }}
              />
            </div>
          </Link>
          <Link download>
            {/* <DownloadOutlined style={{ fontSize: '19px', background: '#F1F4F8', borderRadius: '50px', padding:'8px'}} /> */}
            <div
              style={{
                background: "#F1F4F8",
                borderRadius: "50px",
                margin: "0 10px",
                width: "35px",
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={DownloadMenuImg}
                alt=""
                className=""
                style={{ width: "18px" }}
              />
            </div>
          </Link>
        </div>
      ),
    },
  ];

  const documents = [
    {
      doc_id: 1,
      doc: "doc1",
    },
    {
      doc_id: 2,
      doc: "doc2",
    },
  ];
  // const toggleDropdownRow = (rowId) => {
  //   setDropdownOpenRow((prev) => (prev === rowId ? null : rowId));
  // };

  const toggleDropdownRow = (rowId, event) => {
    const rect = event.target.getBoundingClientRect();
    setDropdownPosition({ top: rect.bottom, left: rect.left });
    setDropdownOpenRow((prev) => (prev === rowId ? null : rowId));
  };

  useEffect(() => {
    // Handle click outside the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpenRow(null); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Documents",
      selector: (row) => row.documents,
      minWidth: '300px',
      cell: (row) => (
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minWidth: "60%",
          }}
        >
          {/* Display document count */}
          <span style={{minWidth: "100px"}}>{row.documents.length} Documents</span>

          {/* Dropdown Trigger */}
          <img
            src={DocumentMenuImg}
            alt=""
            style={{ width: "25px", marginLeft: "15px", cursor: "pointer" }}
            onClick={(e) => toggleDropdownRow(row.mobile, e)} // Pass unique identifier
          />
         <button
          className="btn custom-btn-sec"
         style={{marginLeft:"15px", minWidth: "115px"}}
         onClick={()=>handleRequestDocument(row)}
        >
          Request 
          <img src={DocumentImg} alt="document" style={{ width: '12px'}} className="ms-2"/>
        </button>
          

          {/* Dropdown Content */}
          {dropdownOpenRow === row.mobile && (
            <div
              ref={dropdownRef}
              style={{
                position: "fixed", // Use fixed position
                top: `${dropdownPosition.top}px`, // Dynamically calculate position
                left: `${dropdownPosition.left}px`,
                background: "#fff",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "5px",
                zIndex: 9999, // Ensure it appears above other elements
                padding: "10px",
              }}
            >
              {row.documents.map((doc) => (
                <div
                  key={doc.file_id}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <p
                    className="d-flex align-items-center mb-0"
                    style={{
                      background: "#F1F4F8",
                      borderRadius: "50px",
                      padding: "8px 11px",
                    }}
                  >
                    <img src={DocumentImg} alt="" style={{ width: "14px" }} />
                    <span
                      style={{
                        padding: "0px 15px",
                        whiteSpace: "nowrap",
                        width: "180px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontSize: "12px",
                      }}
                    >
                      {doc.file_name}
                    </span>
                  </p>
                  <div className="d-flex align-items-center ">
                    {/* <EyeOutlined
                      style={{ marginRight: "8px", cursor: "pointer" }}
                      onClick={() => {
                        setModalOpen(true);
                        setImageSrc(doc.file_path);
                      }}
                    /> */}
                    <div
                      style={{
                        background: "#F1F4F8",
                        borderRadius: "50px",
                        margin: "0 10px",
                        width: "35px",
                        height: "35px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setModalOpen(true);
                        setImageSrc(doc.file_path);
                        setFileType(doc.file_type);
                      }}
                    >
                      <img
                        src={ViewMenuImg}
                        alt=""
                        className=""
                        style={{ width: "18px" }}
                      />
                    </div>
                    <div
                      style={{
                        background: "#F1F4F8",
                        borderRadius: "50px",
                        margin: "0 10px",
                        width: "35px",
                        height: "35px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={async () => {
                        const downloadUrl = doc.file_path; // Replace with the actual file path from your data

                        if (downloadUrl) {
                          try {
                            // Fetch the file as a Blob
                            const response = await fetch(downloadUrl);

                            if (!response.ok) {
                              throw new Error(
                                `Failed to download file: ${response.statusText}`
                              );
                            }

                            const blob = await response.blob();

                            // Create an anchor element
                            const link = document.createElement("a");
                            link.href = URL.createObjectURL(blob);

                            // Extract the filename or use a fallback
                            const fileName =
                              downloadUrl.split("/").pop() || "downloaded_file";
                            link.download = fileName;

                            // Append, click, and remove the anchor
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);

                            // Revoke the Blob URL to free memory
                            URL.revokeObjectURL(link.href);
                          } catch (error) {
                            console.error(
                              "Error downloading the file:",
                              error.message
                            );
                          }
                        } else {
                          console.error("File path is not provided");
                        }
                      }}
                    >
                      <img
                        src={DownloadMenuImg}
                        alt=""
                        className=""
                        style={{ width: "18px" }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <button
          className="btn custom-btn-sec"
          onClick={() =>
            handleViewClick(row.mobile, row.name, row.date, row.location)
          }
        >
          View
        </button>
      ),
    },
  ];

  const handleLocationChange = (selectedLocations) => {
    const temp_selected_locations = selectedLocations;
    setselectedLocations(temp_selected_locations);
  };

  useEffect(() => {
    //   fetch('http://192.168.96.111:5000/apis/list_tenant_customers', {
    //     method: 'GET',
    //     headers: { 'Content-Type': 'application/json' }
    // })
    // .then(response => response.json())
    // .then(data => console.log("Fetched Data:", data))
    // .catch(error => console.error("Fetch Error:", error));

    //   get('get_dashboard_locations',{
    //     "days":"7","name":"sac"
    // })
    //   .then((res) => {

    //   })

    get(`get_dashboard_leads?days=${lead_opt_dict[selected]}`)
      .then((res) => {
        setotalLead(res.data.data.total_leads);
        setleadgraphData(res.data.data.leads);
        setotalLeadPercentage(leadData.percentage);
      })
      .catch((error) => {
        if (error.response) {
          console.log("errr");
        }
      });
  }, [selected]);

  // useEffect(() => {
  //   const axiosInstance = axios.create({
  //     baseURL: 'http://192.168.96.111:5000',
  //     timeout: 10000, // Set a global timeout for all requests
  //   });
  //   axios.get('http://192.168.96.111:5000/apis/list_tenant_customers', {
  //     timeout: 10000 // 10 seconds
  //   })
  //   .then(response => {
  //     console.log("Axios fetch success data", response.data);
  //   })
  //   .catch(error => {
  //     console.log("Error occurred:", error.message); // Log a more specific error message
  //   });
  // }, []);

  useEffect(() => {
    // Filter customer data based on selected locations
    if (selectedLocations.length > 0) {
      const filteredData = leadCustomerData.filter((customer) =>
        selectedLocations.includes(customer.location)
      );
      setfilterCustomerData(filteredData);
    } else {
      // If no locations are selected, show all data
      setfilterCustomerData(leadCustomerData);
    }
  }, [selectedLocations]);

  useEffect(() => {
    // Filter customer data based on selected locations
    //
    get(`get_dashboard_locations?days=${lead_opt_dict[selectedLocmenu]}`)
      .then((res) => {
        // console.log("Data:", res.data.data); // Log the data field explicitly
        setotalocations(res.data.data.total_locations);
        setlocationbardata(res.data.data.bardiagram_data);
      })
      .catch((error) => {
        if (error.response) {
          console.log("errr in dashbord_leads");
        }
      });
  }, [selectedLocmenu]);

  const onChangeFilter = async (e) => {
    // debugger;
    const temp_filter_text = e.target.value;
    setfiterText(temp_filter_text);

    // Clear the date filter field when typing in the search input

    var searchData = leadCustomerData.filter((item) => {
      if (
        // item?.firstName
        //   .toString()
        //   .toLowerCase()
        //   .includes(e.target.value.toLowerCase())
        JSON.stringify(item)
          .toLowerCase()
          .indexOf(e.target.value.toLowerCase()) !== -1
      ) {
        return item;
      }
    });

    setfilterCustomerData(searchData);
  };

  const sortByDate = (data) => {
    return data.sort((a, b) => {
      // Function to convert "20 Jan 2004, 8:00PM" into a Date object
      const parseDate = (dateString) => {
        const [datePart, timePart] = dateString.split(", ");
        const [day, month, year] = datePart.split(" ");
        const time = timePart.replace("PM", " PM").replace("AM", " AM"); // Ensure correct time format

        // Months map for converting "Jan", "Feb", etc., into numbers
        const months = {
          Jan: 0,
          Feb: 1,
          Mar: 2,
          Apr: 3,
          May: 4,
          Jun: 5,
          Jul: 6,
          Aug: 7,
          Sep: 8,
          Oct: 9,
          Nov: 10,
          Dec: 11,
        };

        return new Date(`${year}-${months[month] + 1}-${day} ${time}`);
      };

      // Convert both dates and compare them
      const dateA = parseDate(a.date);
      const dateB = parseDate(b.date);

      return dateB - dateA; // Sort in descending order (latest date first)
    });
  };

  const CustomerData = [
    {
      lead_id: 1,
      date: "20 Jan 2004, 8:00PM",
      mobile: "8889999445",
      location: "UAE",
      name: "Mathew Philip",
    },
    {
      lead_id: 4,
      date: "23 Jan 2004, 6:00PM",
      mobile: "5556667774",
      location: "AUS",
      name: "David Johnson",
    },
    {
      lead_id: 5,
      date: "24 Jan 2004, 5:30PM",
      mobile: "4445556662",
      location: "CAN",
      name: "Emma Watson",
    },
    {
      lead_id: 6,
      date: "25 Jan 2004, 4:00PM",
      mobile: "3334445556",
      location: "FRA",
      name: "Lucas Miller",
    },
    {
      lead_id: 7,
      date: "26 Jan 2004, 3:45PM",
      mobile: "2223334448",
      location: "IND",
      name: "Priya Patel",
    },
    {
      lead_id: 2,
      date: "21 Jan 2004, 9:00PM",
      mobile: "7778889991",
      location: "USA",
      name: "John Doe",
    },
    {
      lead_id: 3,
      date: "22 Jan 2004, 7:30PM",
      mobile: "6667778883",
      location: "UK",
      name: "Jane Smith",
    },
    {
      lead_id: 1,
      date: "20 Jan 2004, 8:00PM",
      mobile: "8889999445",
      location: "UAE",
      name: "Mathew Philip",
    },
    {
      lead_id: 4,
      date: "23 Jan 2004, 6:00PM",
      mobile: "5556667774",
      location: "AUS",
      name: "David Johnson",
    },
    {
      lead_id: 5,
      date: "24 Jan 2004, 5:30PM",
      mobile: "4445556662",
      location: "CAN",
      name: "Emma Watson",
    },
    {
      lead_id: 6,
      date: "25 Jan 2004, 4:00PM",
      mobile: "3334445556",
      location: "FRA",
      name: "Lucas Miller",
    },
    {
      lead_id: 7,
      date: "26 Jan 2004, 3:45PM",
      mobile: "2223334448",
      location: "IND",
      name: "Priya Patel",
    },
    {
      lead_id: 2,
      date: "21 Jan 2004, 9:00PM",
      mobile: "7778889991",
      location: "USA",
      name: "John Doe",
    },
    {
      lead_id: 3,
      date: "22 Jan 2004, 7:30PM",
      mobile: "6667778883",
      location: "UK",
      name: "Jane Smith",
    },
  ];

  const fetchData = () => {
    const { startDateString_formatted, endDateString_formatted } = dateRange;

    if (startDateString_formatted && endDateString_formatted) {
      get(
        `get_dashboard_users?start_date=${startDateString_formatted}&end_date=${endDateString_formatted}`
      )
        .then((res) => {
          const temp_user_data = res.data.data;
          setleadCustomerData(temp_user_data);

          const sorted_data = sortByDate(temp_user_data);
          setfilterCustomerData(sorted_data);
        })
        .catch((error) => {
          console.error("Error fetching data with date range:", error);
        });
    } else {
      get("get_dashboard_users")
        .then((res) => {
          const temp_user_data = res.data.data;
          setleadCustomerData(temp_user_data);

          const sorted_data = sortByDate(temp_user_data);
          setfilterCustomerData(sorted_data);
        })
        .catch((error) => {
          console.error("Error fetching data without date range:", error);
        });
    }
  };

  useEffect(() => {
    // Fetch data initially
    fetchData();

    // Set up interval for repeated fetch
    const intervalId = setInterval(() => {
      fetchData();
      setTimer(15); // Reset timer after fetch
    }, 15000);

    // Countdown logic
    const countdownInterval = setInterval(() => {
      setTimer((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    // Cleanup on unmount
    return () => {
      clearInterval(intervalId);
      clearInterval(countdownInterval);
    };
  }, [dateRange]);

  const handleRefresh = () => {
    fetchData();
    setTimer(15); // Reset timer on manual refresh
  };

  useEffect(() => {
    get("get_locations").then((res) => {
      setlocationfullList(res.data.data.locations);
    });
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const handleLeadMenuClick = (e) => {
    const selectedItem = items.find((item) => item.key === e.key);
    if (selectedItem) {
      setSelected(selectedItem.value); // Update selected value
      // console.log(selectedItem)
    }
  };

  const handleLocationMenuClick = (e) => {
    const selectedItem = items.find((item) => item.key === e.key);
    if (selectedItem) {
      setselectedLocmenu(selectedItem.value); // Update selected value
    }
  };
  const leadMenu = (
    <Menu onClick={handleLeadMenuClick}>
      {items.map((item) => (
        <Menu.Item key={item.key}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  const locationMenu = (
    <Menu onClick={handleLocationMenuClick}>
      {items.map((item) => (
        <Menu.Item key={item.key}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div>
      <Sidebar isSidebarOpen={isSidebarOpen} />
      <section className="home-section">
        <Header toggleSidebar={toggleSidebar} />

        <div className="project-task">
          <div className="inside-box p-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex align-items-center justify-content-end  mb-4">
                  <div>
                    <form action="">
                      <div
                        className="bg-light rounded rounded-pill shadow-sm me-3"
                        style={{
                          border: "1px solid #DEDEDE",
                          minWidth: "370px",
                        }}
                      >
                        <div className="input-group search-space">
                          <Input
                            type="search"
                            placeholder="Search with Name or Mobile number"
                            value={fiterText}
                            onChange={onChangeFilter}
                            aria-describedby="button-addon1"
                            className=" ms-2 form-control rounded-pill border-0 bg-light"
                          />
                          <div className="input-group-append">
                            <button
                              id="button-addon1"
                              type="submit"
                              className="btn btn-link text-primary border-0"
                            >
                              <img src={SearchImg} alt="" className="" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  {/* <div>
                    <div className="dropdown sort-btn" style={{ marginLeft: '15px'}}
                     >
                      <a href="#" className=" custom-dropdown-toggle" data-bs-toggle="dropdown" style={{ padding: '9px 35px 9px 15px', border: '1px solid #DEDEDE', borderRadius: '20px' }}>
                       <img src={sortImg} alt="" style={{ width: '20px' }} className="me-2" /> Filter by location
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li><a className="dropdown-item" href="#"> Last 2 Days</a></li>
                        <li><a className="dropdown-item" href="#"> Last 1 Days</a></li>
                        <li><a className="dropdown-item" href="#"> Last 4 Days</a></li>
                      </ul>
                    </div>
                  </div> */}

                  {/* <div>
                    <LocationCheckboxDropdown
                      locations={locationfullList}
                      onLocationChange={handleLocationChange}
                    />
                  </div> */}

                  {/* <div>
                    <div className="dropdown sort-btn" style={{  marginLeft: '15px' }}
                   >
                      <a href="#" className=" custom-dropdown-toggle" data-bs-toggle="dropdown" style={{ padding: '9px 35px 9px 15px', border: '1px solid #DEDEDE',
                      borderRadius: '20px' }}>
                       <img src={dateImg} alt="" style={{ width: '20px' }} className="me-2" /> Any Date
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li><a className="dropdown-item" href="#"> Last 2 Days</a></li>
                        <li><a className="dropdown-item" href="#"> Last 1 Days</a></li>
                        <li><a className="dropdown-item" href="#"> Last 4 Days</a></li>
                      </ul>
                    </div>
                  </div> */}
                  <div>
                    <FilterByDate onDateRangeChange={handleDateRangeChange} />
                  </div>
                  <div className="ms-4">
                    <button className="btn btn-outline-primary" onClick={showModal}>Add Patient</button>
                  </div>
                </div>
              </div>
              <Modal
  title={
    <div className="modal-headers">
      <span>{isEdit ? "Edit Patient Details" : "Add Patient Details"}</span>
      <div className="modal-actions">
      <button className="btn btn-secondary" onClick={handleCancel}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={handleOk}>
          Save
        </button>
      </div>
    </div>
  }
  open={isModalOpen}
  onCancel={handleCancel}
  closable={false} // Removes the close icon
  footer={null} // Hides default footer buttons
  className="add-patient"
>
  <div className="form-group mt-3">
    <label>Name</label>
    <input
      className="form-control"
      type="text"
      placeholder="Patient Name"
      value={patientName}
      onChange={handlePatientName}
    />
    {errors.patientName && <span className="text-danger">{errors.patientName}</span>}
  </div>
  <div className="form-group mt-3">
    <label>Mobile</label>
    <input
      className="form-control"
      type="text"
      placeholder="Contact Number"
      value={patientContactNumber}
      disabled={isEdit}
      onChange={(e) => {
        const input = e.target.value;
        if (/^\d*$/.test(input)) {
          setpatientContactNumber(input);
        }
      }}
    />
    {errors.patientContactNumber && (
      <span className="text-danger">{errors.patientContactNumber}</span>
    )}
  </div>
  <div className="mt-3 mb-4">
    <label>List of Documents</label>
    <Select
      mode="tags"
      style={{ width: "100%" }}
      placeholder="Tags Mode"
      value={selectedlisteofdoc}
      onChange={handleChange}
      options={options}
    />
    {errors.selectedlisteofdoc && (
      <span className="text-danger">{errors.selectedlisteofdoc}</span>
    )}
  </div>
</Modal>


              {/* <Modal title="Request Patient Documents" open={isRequestModalOpen} onOk={handleRequestOk} onCancel={handleRequestCancel}>
                <div className="form-group mt-3">
                  <label>
                    Name
                  </label>
                  <input 
                  className="form-control"
                   type="text"
                  placeholder="Patient Name"
                  value={requestedpatientName}
                  onChange={(e)=>
                    setRequestedpatientName(e.target.value)}
                  />
                </div>
                <div className="form-group mt-3">
                  <label>
                    Mobile
                  </label>
                  <input 
                  className="form-control"
                  type="text"
                  placeholder="Contact Number"
                  value={requestedpatientContactNumber}
                  onChange={(e) => {
                    const input = e.target.value;
                    // Allow only digits
                    if (/^\d*$/.test(input)) {
                      setRequestedpatientContactNumber(input);
                    }
                  }}
                  />
                </div>
                <div className="mt-3 mb-4">
                  <label>List of Documents</label>
                  <Select
                    mode="tags"
                    style={{
                      width: '100%',
                    }}
                    placeholder="Tags Mode"
                    // value={selectedRequestedlisteofdoc}
                    value={selectedRequestedlisteofdoc}
                    onChange={handleRequestChange}
                    options={options}
                  />
                </div>
              </Modal> */}
              <div className="row">
                <div className="col-lg-12 position-relative">
                  <DataTable
                    columns={columns}
                    data={filterCustomerData}
                    highlightOnHover
                    customTheme={myNewTheme}
                    pagination
                    options={{
                      disableSelectionOnClick: true,
                      noHeader: true,
                    }}
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                    paginationComponentOptions={{
                      rowsPerPageText: "Records per page:",
                      rangeSeparatorText: "out of",
                    }}
                    className="table-style"
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: "8px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <button className="btn btn-primary" onClick={handleRefresh}>
                      Refresh
                    </button>
                    <span style={{ marginLeft: "10px", fontSize: "14px" }}>
                      Next auto-refresh in: {timer}s
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--  --> */}
      </section>
      <Modal
        title="Uploaded Document"
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
      >
        {fileType !== "pdf" ? (
          imageSrc ? (
            <img
              src={imageSrc}
              alt="Uploaded"
              style={{ width: "100%", height: "500px", objectFit: "contain" }}
            />
          ) : (
            <p>No image to show</p>
          )
        ) : (
          <iframe
            src={imageSrc}
            title="PDF Viewer"
            style={{ width: "100%", height: "500px", border: "none" }}
          />
        )}
      </Modal>
    </div>
  );
};

export default DashboardCopy;
